export default function Footer({

}) {
    return (
        <>
            design &amp; development by Frantisek Hajnovic<br/>
            <a href="http://ferohajnovic.com">ferohajnovic.com</a><br/>
            copyright © 2020, all rights reserved <br/>
            pic credits &nbsp;
            <a href="https://www.instagram.com/eo.lenka/">Lenka</a>,
            <a href="https://www.instagram.com/garethsphotography/">Gareth</a>,
            Kika,
            Stevo
             
            <br/>
            
            <div>
                <a href="https://www.facebook.com/feromusician" className="fa fa-facebook"></a>
                <a href="https://www.instagram.com/fero.hajnovic" className="fa fa-instagram"></a>
                <a href="https://soundcloud.com/ferohajnovic" className="fa fa-soundcloud"></a>
                <a href="https://fero.bandcamp.com" className="fa fa-bandcamp"></a>
                <a href="https://www.youtube.com/channel/UCbFZJZ1bf8nMcLPJie5-mTA" className="fa fa-youtube"></a>
            </div>
        </>
    );
  }
  